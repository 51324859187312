<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        {{ $t("manageproducttype") }}
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >{{ $t("add") }}
        </button>
      </div>
    </div>

    <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          :placeholder="$t(`search`)"
          type="text"
          class="form-control"
        />
        <!-- <datalist id="datalistOptions">
          <option
            v-for="(item, index) in allData"
            :key="index"
            :value="item.sub_product_group_code"
          >
            {{ item.name }}
          </option>
        </datalist> -->
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          {{ $t("search") }}
        </button>
      </div>
    </div>

    <div class="card-body pt-3">
      <!-- searchbar -->
      <!-- <Search @textSearch="textSearch" /> -->
      <!-- searchbar -->

      <div class="table-responsive">
        <a-table :dataSource="dataItemsShow" :columns="columns">
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'no'">
              {{ $t("no") }}
            </template>
            <template v-if="column.dataIndex === 'sub_product_group_code'">
              {{ $t("producttypecode") }}
            </template>
            <template v-if="column.dataIndex === 'sub_product_group_name'">
              {{ $t("producttypename") }}
            </template>
            <template v-if="column.dataIndex === 'productGroup.name'">
              {{ $t("mainproductname") }}
            </template>
          </template>

          <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'no'">
              {{ record.no }}
            </template>
            <template v-if="column.dataIndex === 'productGroup'">
              <span v-if="record.productGroup">
                {{ record.productGroup ? record.productGroup.code : "" }}
                {{
                  record.productGroup ? " | " + record.productGroup.name : ""
                }}
              </span>
            </template>
            <template v-if="column.dataIndex === 'cost'">
              <span v-if="record.productPrices.length != 0">
                {{ record.productPrices[0].cost }}
              </span>
              <span v-else>-</span>
            </template>
            <template v-if="column.dataIndex === 'status'">
              <span v-if="record.status == 'ขายปกติ'" style="color: green">{{
                record.status
              }}</span>
              <span v-else style="color: red">{{ record.status }}</span>
            </template>
            <template v-if="column.dataIndex === 'action'">
              <div class="row">
                <div class="col-sm-6 d-flex justify-content-md-end">
                  <button
                    type="button"
                    class="
                      btn btn-link btn-color-muted btn-active-color-success
                      p-0
                    "
                    @click="goToEdit(record.id)"
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>
                </div>
                <div class="col-sm-6">
                  <button
                    @mouseenter="hoverBBtn"
                    type="button"
                    class="
                      btn btn-link btn-color-muted btn-active-color-danger
                      p-0
                    "
                    @click="goToDelete(record, index)"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                </div>
              </div>
            </template>
          </template>
        </a-table>

        <!-- <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200"
            >
              <th>{{ $t("no") }}</th>
              <th>{{ $t("producttypecode") }}</th>
              <th>{{ $t("producttypename") }}</th>
              <th>{{ $t("mainproductname") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="5">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ !showing1 ? index + 1 : showing1 + index }}</td>
              <td>{{ item.sub_product_group_code }}</td>
              <td>{{ item.sub_product_group_name }}</td>
              <td>
                {{ item.productGroup ? item.productGroup.name : "" }}
              </td>
              
              <td>
                <div class="row">
                  <div class="col-sm-6 d-flex justify-content-md-end">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-success
                        p-0
                      "
                      @click="goToEdit(item.id)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </div>
                  <div class="col-sm-6 d-flex">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-danger
                        p-0
                      "
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <!-- <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div> -->
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";

export default {
  setup() {
    document.title = "J&N | เพิ่มกลุ่มสินค้ารอง";
  },
  components: {
    DialogConfirmByPass,
    Pagination,
    Search,
    Loader,
  },
  data: () => ({
    columns: [
      { title: "no", dataIndex: "no" },
      { title: "producttypecode", dataIndex: "sub_product_group_code" },
      { title: "producttypename", dataIndex: "sub_product_group_name" },
      { title: "mainproductname", dataIndex: "productGroup.name" },
      { title: "", dataIndex: "action" },
    ],

    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    dialogConfirmByPass: false,

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    allData: [],
  }),

  created() {
    this.getAll();
  },

  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.subProduct.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end
    goToNew() {
      this.$router.push({
        path: "/warehouse/WH2SUB/new",
        query: {},
      });
    },
    goToEdit(id) {
      this.$router.push({
        name: "WH2SUB-edit",
        query: {
          id: id,
        },
      });
    },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2delete")} ${item.name} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await whApi.subProduct.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: `${this.$t("deletesuccessfull")} `,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },
    async statusApprove(isApprove) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 2,
      };
      updateResponse = await whApi.subProduct.update(id, this.itemApprove);
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          Swal.fire({
            icon: "success",
            title: `อนุมัติสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        this.getAll(1);
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },
    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    textSearch(val) {
      console.log({ val });
    },

    async search() {
      this.loading = true;

      const responseSearch = await whApi.subProduct.search({
        search: this.searchInput,
      });

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
